<template>
    <div class="guidence">
        <div class="list" v-if="list.length">
            <div class="item" v-for="item in list" :key="item._id">
                <div class="time">{{ item.writeAt }}</div>
                <div class="title click-op" @click="goTo(item._id)">
                    {{ item.title | language }}
                </div>
            </div>
        </div>
        <error v-else></error>
        <div class="flex"></div>
        <Page
            :total="total"
            :current="pageNum"
            :page-size="pageSize"
            @on-change="handlePageChange"
            style="margin-top:40px;margin-bottom:40px;"
            show-total
        >
            <span class="ivu-page-total" style="font-size:16px;"
                >{{ "共有" | language }} {{ total }} {{ "条" | language }}</span
            >
        </Page>
    </div>
</template>

<script>
import Error from "../../compontents/Error.vue";
export default {
    components: {
        Error
    },
    data() {
        return {
            list: [],
            pageSize: 10,
            pageNum: 1,
            total: 0
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        //分页
        handlePageChange(page) {
            this.pageNum = page;
            this.fetchData();
        },
        goTo(id) {
            this.$router.push(`/base/service/guidence/detail/${id}`);
        },
        async fetchData() {
            let res = await this.$http.get(
                `/hall/guide?pageSize=${this.pageSize}&pageNum=${this.pageNum -
                    1}`
            );

            this.total = res.count;
            this.list = res.result;
        }
    }
};
</script>

<style lang="less" scoped>
.guidence {
    display: flex;
    flex-direction: column;
    min-height: 750px;
    .list {
        padding-top: 20px;
        border-top: 1px solid #d5d7da;
        .item {
            display: flex;
            align-items: center;
            padding-left: 10px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #d5d7da;
            .time {
                height: 22px;
                font-size: 16px;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.3);
                line-height: 22px;
            }
            .title {
                margin-left: 140px;
                height: 29px;
                font-size: 16px;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.9);

                opacity: 0.9;

                line-height: 29px;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
    .flex {
        flex: 1;
    }
}
</style>
